import { Footer, Header } from "../../components";
import page1 from "../../page-1.png";
import page2 from "../../page-2.png";
import page3 from "../../page-3.png";
import "./home.css";

export function Home() {
  return (
    <div className="home">
      <Header />
      <div className="script">
        <img src={page1} alt="page 1 of script" />
        <img src={page2} alt="page 2 of script" />
        <img src={page3} alt="page 3 of script" />
      </div>
      <Footer />
    </div>
  );
}
