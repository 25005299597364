import { Header } from "../../components";
import "./error.css";

export function Error() {
  return (
    <div>
      <Header />
      <div className="error">
        <p>Page not found or there was an error</p>
      </div>
    </div>
  );
}
