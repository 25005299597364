import { Link } from "react-router-dom";
import "./header.css";

export function Header() {
  return (
    <header>
      <nav>
        <ul>
          <li>
            <Link to="/listen">Listen</Link>
          </li>
          <li>
            <Link to="/watch">Watch</Link>
          </li>
          <li>
            <Link to="/buy">Buy</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
