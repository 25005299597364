import { Footer, Header } from "../../components";
import "./watch.css";

export function Watch() {
  return (
    <div className="page">
      <Header />
      <div className="watch">
        <iframe
          src="https://www.youtube.com/embed/JznKbYb4kQs"
          title="Stay Over - OLIVER"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowFullScreen
          className="youtube"
        ></iframe>
      </div>
      <Footer />
    </div>
  );
}
