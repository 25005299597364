import { Footer, Header } from "../../components";
import "./buy.css";
import pants from "../../pants.png";

export function Buy() {
  return (
    <div className="page">
      <div>
        <Header />
        <div className="buy">
          <img
            src={pants}
            alt="brown pants with orange printed above pocket"
            className="pants"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
