import { Link } from "react-router-dom";
import "./footer.css";

export function Footer() {
  return (
    <footer>
      <Link to="/">
        <h1>STAY OVER</h1>
      </Link>
    </footer>
  );
}
