import * as THREE from "three";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { Footer, Header } from "../../components";
import "./listen.css";
import { Suspense, useEffect, useRef, useState } from "react";
import { Lights } from "../Landing";
import { Preload } from "@react-three/drei";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  // const [windowSize, setWindowSize] = useState<{ width: number | undefined, height: number | undefined }>({
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    // width: undefined,
    // height: undefined,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function CdCase() {
  const windowSize = useWindowSize();

  const shortEdge =
    windowSize.width > windowSize.height ? windowSize.height : windowSize.width;

  const dimensions = {
    // width: 14.2,
    // height: 12.5,
    // depth: 1,
    width: shortEdge * 0.035,
    height: shortEdge * (12.5 / 14.2) * 0.035,
    depth: 1,
  };

  const ref = useRef<THREE.Mesh>(null!);
  const texture = useLoader(THREE.TextureLoader, "/cover.jpeg");

  useFrame((state) => {
    ref.current.rotation.x = -state.mouse.y * 0.25;
    ref.current.rotation.y = state.mouse.x * 0.25;
  });

  return (
    <group>
      <mesh ref={ref} position={[0, 0, -15]}>
        <boxGeometry
          args={[dimensions.width, dimensions.height, dimensions.depth]}
        />
        <meshStandardMaterial map={texture} />
      </mesh>
    </group>
  );
}

export function Listen() {
  return (
    <div className="page">
      <div>
        <Header />
        <div className="listen">
          {/* <Canvas
            // className="canvas"
            dpr={[1.5, 2]}
            camera={{
              position: [0, 0, 30],
              fov: 45,
              near: 1,
              far: 100,
            }}
            style={{ height: "75vh", background: "none", touchAction: "none" }} // if mobile is broken this may be why
          >
            <Suspense fallback={null}>
              <Lights />
              <CdCase />
              <Preload all />
            </Suspense>
          </Canvas> */}
          <iframe
            src="https://open.spotify.com/embed/album/57Uv4Ecs2FEdFr6bmo9wBh?utm_source=generator&theme=0"
            title="Sorryyyy! by Stay Over on Spotify"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            className="embed"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
}
