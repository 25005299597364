import * as THREE from "three";
import { Suspense, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  Center,
  Float,
  Preload,
  PresentationControls,
  Text3D,
} from "@react-three/drei";
import { useNavigate } from "react-router-dom";
import "./landing.css";

export function Lights() {
  const directionalLight = useRef(null!);
  // useHelper(directionalLight, THREE.DirectionalLightHelper, 1, "cyan");

  const spotLight = useRef(null!);
  // useHelper(spotLight, THREE.SpotLightHelper, "cyan");

  return (
    <group>
      <ambientLight intensity={0.2} />
      <directionalLight
        ref={directionalLight}
        position={[0, 0, 10]}
        intensity={0.1}
      />
      <spotLight ref={spotLight} position={[-10, 2, 10]} intensity={0.5} />
    </group>
  );
}

export function Landing() {
  const navigate = useNavigate();

  function handleText3DClick(event: any) {
    if (event.delta < 4) {
      navigate("/home");
    }
  }

  function Text() {
    const ref = useRef<THREE.Mesh>(null!);

    useFrame((state, delta) => (ref.current.rotation.y += delta * 0.01));

    return (
      <PresentationControls global polar={[-Infinity, Infinity]}>
        <Float
          speed={0.5}
          floatingRange={[-0.25, 0.25]}
          rotationIntensity={0.25}
        >
          <Center>
            <Text3D
              ref={ref}
              onClick={handleText3DClick}
              font="./font.json"
              bevelEnabled
              bevelThickness={2}
              scale={3}
              letterSpacing={-0.08}
              castShadow
              receiveShadow
            >
              STAY OVER
              <meshStandardMaterial color={0x6f0024} metalness={0.1} />
            </Text3D>
          </Center>
        </Float>
      </PresentationControls>
    );
  }

  return (
    <Canvas
      className="canvas"
      dpr={[1.5, 2]}
      camera={{
        position: [0, 0, 30],
        fov: 45,
        near: 1,
        far: 100,
      }}
      style={{ background: "#030001", touchAction: "none" }} // if mobile is broken this may be why
    >
      <Suspense fallback={null}>
        <Lights />
        <Text />
        <Preload all />
      </Suspense>
    </Canvas>
  );
}
